import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: `/`,
    name: 'Presentation',
    component: function() {
      return import('../views/Presentation')
    }
  },
  {
    path: `/watch`,
    name: 'Presentation Viewer',
    component: function() {
      return import('../views/PresentationWatch')
    }
  },
  {
    path: `/chat`,
    name: 'Presentation Chat',
    component: function() {
      return import('../views/PresentationChatPopout')
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
